import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import './layout.css';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      {/*<Header siteTitle={data.site.siteMetadata.title} />*/}
      <Box>
        <main>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </main>
        <footer>
          ©{new Date().getFullYear()}, by cjost.de
        </footer>
      </Box>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
